import CenterLayout from '~/components/symlinked/admin/layouts/center-layout.js';
import * as CSS from './Footer.css';
import clsx from '~/forms/utils/clsx';

export default p => (
    <div class={CSS.Footer}>
        <CenterLayout>
            <div class={clsx(CSS.FooterList, 'content')}>
                <a href="/privacy-policy">Privacy Policy</a>
                <a href="/terms-of-service">Terms of Service</a>
                <a href="/attributions">Attributions</a>
            </div>
        </CenterLayout>
    </div>
)