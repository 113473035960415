import clsx from '~/forms/utils/clsx';
import Footer from './Footer.js';
import Menu from './Menu.js';

export default (p) => {
    return (<div class={clsx(p.class)}>
        <Menu />

        {p.children}

        {/* <Footer /> */}
    </div>)
}
