import HeroHeader from './HeroHeader.js';
import HeroDescription from './HeroDescription.js'
import StandardInput from './StandardInput.js'
import ButtonPrimary from './ButtonPrimary.js'
import * as CSS from './Hero.css';
import { useState } from "preact/hooks"

export default p => {
    // var [email, setEmail] = useState('');

    return (
        <div class={CSS.Hero}>
            <HeroHeader />
            <HeroDescription />

            <div class={CSS.CTASignUp}>
                {/* <StandardInput class={CSS.PrimaryInput} placeholder="Enter your business email" onInput={e => setEmail(e.target.value)} /> */}
                <a class={CSS.CTALink} href={`//admin.appoint.space/auth/sign-up`}> {/* password?email=${email} */}
                    <ButtonPrimary class={CSS.PrimaryButton}>Sign up free</ButtonPrimary>
                </a>
            </div>

            <div class={CSS.Triforce}>
                <div class={CSS.Force}>✓ Calendar integration</div>
                <div class={CSS.Force}>✓ Reminders</div>
                <div class={CSS.Force}>✓ Live support</div>
            </div>

        </div>
    )
}