import HeroSection from './HeroSection.js';
import Section from './Section.js';
import H from './H.js';
import D from './D.js';
import CTA from './CTA.js';
import HomeLayout from './HomeLayout.js';

export default () => (
    <HomeLayout class="medium">
        <HeroSection />
    </HomeLayout>
)