import CenterLayout from '~/components/symlinked/admin/layouts/center-layout.js';
import Icon from './Icon.js';
import Brand from './Brand.js';
import MenuItem from './MenuItem.js';
import * as CSS from './Menu.css';
import { useState } from 'preact/hooks';

var menu = [
  // ["/features", "Features"],
  // ["/pricing", "Pricing"],
  [`//admin.appoint.space/auth/sign-in`, "Sign In"],
  [`//admin.appoint.space/auth/sign-up`, "Sign Up"],
]

export default p => {
  var [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div class={CSS.BottomBorder}>
      <CenterLayout>
        <div class={CSS.Main}>
          <Brand />
          <div class={CSS.Menu}>
            <div class={CSS.Long}>
              {renderItems()}
            </div>
            <div class={CSS.Burger} onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {renderBurgerownia()}
            </div>
          </div>
        </div>
        {isMenuOpen ?
          (
            <div class={CSS.Mobile}>
              {renderItems()}
            </div>
          )
          : null}
      </CenterLayout>
    </div>
  )

  function renderItems() {
    return menu.map(i => <MenuItem class={CSS.MenuItem} url={i[0]} name={i[1]} />);
  }

  function renderBurgerownia() {
    if (isMenuOpen) {
      return (
        <Icon name="flaticon-298-folder-12" class={CSS.BurgerIcon} type="small" />
      )
    }

    return (
      <Icon name="flaticon-018-menu-1" class={CSS.BurgerIcon} type="small" />
    )
  }
}
