// import AppointSpaceLogo from '~/admin/src/assets/svg/appoint-space-logo.svg';
import AppointSpaceIcon from '~/admin/assets/svg/appoint-space-icon.svg';
import CSS from './Brand.css';


export default p => (
  <a href="/" class={CSS.Brand}>
    <img src={AppointSpaceIcon} />
    <div class={CSS.Keywords}>
      <div class={CSS.Line1}>AppointSpace</div>
      <div class={CSS.Line2}>Have a meeting</div>
    </div>
  </a>
)